import React, { Component } from "react"
import PropTypes from "prop-types"
import Carousel from "nuka-carousel"
import VisuallyHidden from "@reach/visually-hidden"
import { CarouselWrapper } from "./ComicList.css"

///

class CarouselComicList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPost: 0,
    }
  }

  componentDidMount() {
    if (this.props.currentPost) {
      this.setState({
        currentPost: this.props.currentPost,
      })
    }
  }

  render() {
    const { children } = this.props
    const slideNum = 5

    // slider options
    // https://github.com/FormidableLabs/nuka-carousel#props
    const settings = {
      enableKeyboardControls: true,
      // heightMode: "first", // not working
      // initialSlideHeight: 300, // not working
      slideIndex: this.state.currentPost,
      slidesToScroll: slideNum,
      slidesToShow: slideNum,
      slideWidth: 1,
    }

    return (
      <CarouselWrapper>
        <Carousel
          {...settings}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <button
              className="ArcCarousel-nav-btn"
              onClick={previousSlide}
              disabled={currentSlide === 0}
            >
              &#x25c4;<VisuallyHidden> Prev</VisuallyHidden>
            </button>
          )}
          renderCenterRightControls={({
            nextSlide,
            currentSlide,
            slideCount,
          }) => (
            <button
              className="ArcCarousel-nav-btn"
              onClick={nextSlide}
              disabled={currentSlide >= slideCount - slideNum}
            >
              <VisuallyHidden>Next </VisuallyHidden>&#x25ba;
            </button>
          )}
          renderBottomCenterControls={() => null}
        >
          {children}
        </Carousel>
      </CarouselWrapper>
    )
  }
}

CarouselComicList.propTypes = {
  children: PropTypes.node,
  currentPost: PropTypes.number,
}

export default CarouselComicList
