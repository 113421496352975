import styled, { css } from "styled-components"
import theme from "../../../config/theme"
import { linkCoverParent, truncate } from "../../../config/mixins"
import { fontSize, lineHeight, space, width } from "styled-system"
import { Link } from "gatsby"
import { transparentize } from "polished"

///

const listThumbSize = "4rem"
const thumbnailBg = theme.colors.fgMuted[5]

const comicItemBase = css`
  // transition: all 0.2s ease;
  position: relative;
  list-style: none;
  background: ${theme.colors.cardBg};

  img {
    object-fit: cover;
  }
`
const BaseTitle = styled.h3`
  ${fontSize}
  ${lineHeight}
  ${space}
  font-family: ${theme.fonts.heading};
`

///

export const CardItemWrapper = styled.li`
  ${comicItemBase}
  ${space}

  max-width: ${theme.thumbnailMaxWidth};
  text-align: center;
  border-radius: 0.25rem;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);

  /* &:hover {
    box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.2),
      0 2px 8px 4px rgba(60, 64, 67, 0.1);
    
    img {
      transform: scale(1.05);
    }
  } */

  &:last-child {
    margin-bottom: 0;
  }

  img {
    transition: transform .2s ease-in-out;
  }
`
CardItemWrapper.defaultProps = {
  mb: [3, 4],
}

///

export const CardThumbnail = styled.div`
  /**
    * set container height
    * https://www.andyshora.com/css-image-container-padding-hack.html 
   */
  background-color: ${thumbnailBg};
  height: 0;
  padding-bottom: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`

///

export const CardLink = styled(Link)`
  /* ${linkCoverParent()} */
  text-decoration: none;
`

///

export const CardButton = styled(CardLink)`
  ${space}

  display: inline-block;
  font-family: ${theme.fonts.heading};
  font-weight: 700;
  text-transform: uppercase;
  border: 0.125rem solid ${theme.colors.fg};

  &:hover,
  &:focus {
    background-color: ${theme.colors.secondary};
  }

  &:not(:only-child):not(:first-child) {
    margin-left: -0.125rem;
  }
`
CardButton.defaultProps = {
  px: 3,
  py: 2,
  mb: 2,
}

///

export const CardButtonExt = styled(CardButton).attrs({
  as: "a",
  target: "_blank",
  rel: "external nofollow noopener noreferrer",
})`
  .external-icon {
    font-size: 66%;
  }
`

///

export const CardTitle = styled(BaseTitle)``
CardTitle.defaultProps = {
  fontSize: [4, 5],
  lineHeight: 2,
  px: 4,
  py: 3,
}

///

export const CardDesc = styled.div`
  ${space}

  p {
    white-space: pre-line;
    line-height: ${theme.lineHeights[2]};
  }
`
CardDesc.defaultProps = {
  px: [3, 4],
  py: 3,
}

/** ==================================== */

export const ListItemWrapper = styled.li`
  ${comicItemBase}
  ${space}

  border: 1px solid #aaa;
  margin-bottom: -1px; // balance border
  min-height: ${listThumbSize};
  display: grid;
  grid-template-columns: ${listThumbSize} 1fr auto;

  &:hover {
    background-color: ${theme.colors.fgMuted[6]};
  }

  > * {
    align-self: center;
  }
`

///

export const ListThumbnail = styled.div`
  background-color: ${thumbnailBg};
  overflow: hidden;
  height: ${listThumbSize};

  img {
  }
`

///

export const ListTitle = styled(BaseTitle)`
  ${truncate()}
`
ListTitle.defaultProps = {
  fontSize: [2, 3],
  pl: [2, 3],
  pr: 0,
}

///

export const ListLink = styled(Link)`
  ${linkCoverParent()}
  ${truncate()}

  line-height: ${listThumbSize};
  display: inline-block;

  &:not(:hover) {
    text-decoration: none;
  }

  &:focus {
    outline: none;
    z-index: 1; // bring focus style above other items

    &:before {
      outline: 2px auto ${transparentize(0.5, theme.colors.accent)};
    }
  }
`

///

export const ListDate = styled.div`
  ${fontSize}
  ${space}

  text-align: right;
  opacity: 0.75;
`
ListDate.defaultProps = {
  fontSize: [0, 1],
  px: [2, 3],
}

/** ==================================== */

export const MiniItemWrapper = styled.li`
  ${comicItemBase}
  ${space}
  ${width}

  background: transparent;

  &:hover {
    background-color: ${transparentize(0.75, theme.colors.accent)};
  }
`

///

export const MiniThumbnail = styled.div`
  background-color: ${thumbnailBg};
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }

  .is-active & img {
    border: 0.25rem solid ${theme.colors.accent};
  }
`

///

export const MiniTitle = styled(BaseTitle).attrs({ as: "h5" })`
  ${truncate()}

  .is-active & {
    color: ${theme.colors.accent};
  }
`
MiniTitle.defaultProps = {
  fontSize: [1, 2],
  pt: [1, 2],
  pb: 1,
}

///

export const MiniLink = styled(Link)`
  ${linkCoverParent()}

  display: block;

  &.is-active:hover,
  &:not(:hover) {
    text-decoration: none;
  }
`
