import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { space } from "styled-system"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import StickyMenu from "../components/StickyMenu"
import ComicListItem from "../components/ComicListItem"
import { CarouselComicList } from "../components/ComicList"
import { SocialListTextOnly } from "../components/SocialList"

///

const ComicBody = styled.div`
  ${space}
  min-height: 90vh;
  text-align: center;
`

class ComicPostTemplate extends React.Component {
  render() {
    const post = this.props.data.post
    const parentPost = this.props.data.parentPost
    const { parentSlug } = this.props.pageContext
    let { siblingPosts } = this.props.pageContext
    const baseUrl = this.props.data.site.siteMetadata.siteUrl

    // =================
    // process sibling posts:
    // - get current post index (for bottom slider/carousel)
    // - get prev/next post
    // =================

    let previous
    let next
    let prevUrl
    let nextUrl
    let siblingCarousel

    if (typeof siblingPosts !== "undefined") {
      // find sibPost that has same ID as post
      const currPost = siblingPosts.filter(siblingPost => {
        return siblingPost.node.fields.slug === post.fields.slug
      })

      // find index of that sibPost
      const currPostIndex = siblingPosts.indexOf(currPost[0])

      // define prev & next,
      // then make objects for Pagination component
      if (siblingPosts[currPostIndex - 1]) {
        previous = siblingPosts[currPostIndex - 1].node
        prevUrl = previous.fields.slug.replace("comics/", "")
      }
      if (siblingPosts[currPostIndex + 1]) {
        next = siblingPosts[currPostIndex + 1].node
        nextUrl = next.fields.slug.replace("comics/", "")
      }

      // create carousel node
      siblingCarousel = (
        <>
          {siblingPosts.length > 1 && (
            <CarouselComicList currentPost={currPostIndex - 2}>
              {siblingPosts.map(({ node }) => (
                <ComicListItem
                  key={node.fields.slug}
                  variant="mini"
                  as="div"
                  itemTitle={
                    node.frontmatter.ep
                      ? node.frontmatter.ep + ": " + node.frontmatter.title
                      : node.frontmatter.title
                  }
                  url={node.fields.slug.replace("comics/", "")}
                  thumbnail={
                    node.frontmatter.thumbnail
                      ? node.frontmatter.thumbnail.childImageSharp.resize.src
                      : null
                  }
                />
              ))}
            </CarouselComicList>
          )}
        </>
      )
    }

    // =================
    // get parent post
    // =================

    let parentPostTitle = "Back"
    let parentPostUrl = "/"
    if (parentPost) {
      parentPostTitle = parentPost.frontmatter.title
      parentPostUrl = parentSlug.replace("comics/", "")

      // If a bilingual comic (ie. ends with "en"/"id"), skip one level up

      let lastSegment
      const pathArray = this.props.location.pathname.split("/")

      if (pathArray[pathArray.length - 1]) {
        lastSegment = pathArray.pop()
      } else {
        pathArray.pop()
        lastSegment = pathArray.pop()
      }

      if (lastSegment === "en" || lastSegment === "id") {
        const skipUp = pathArray.pop()
        console.log("skipUp ", skipUp) // prevent warning
        parentPostUrl = pathArray.join("/")
        parentPostTitle = "Back"
      }
    }

    // =================
    // get description for meta
    // =================

    let postDescription = "From " + parentPostTitle
    if (post.frontmatter.description) {
      postDescription = post.frontmatter.description.substring(0, 280)
    }

    // =================
    // make socmed share links
    // =================

    const twitterShareText = `${post.frontmatter.title} by Azam Raharjo ${this.props.location.href}`
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      twitterShareText
    )}&via=AzamRaharjo&source=webclient`
    const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      this.props.location.href
    )}`

    // =================
    // get language & image for SEO
    // =================

    let seoLang = "en"
    if (post.frontmatter.lang) {
      if (post.frontmatter.lang.length === 1 && post.frontmatter.lang[0] === "id") {
        seoLang = "id"
      }
    }

    let seoImg
    if (parentPost) {
      if (typeof parentPost.frontmatter.thumbnail !== "undefined") {
        seoImg = baseUrl + parentPost.frontmatter.thumbnail.childImageSharp.resize.src
      }
    }

    const seoUrl = baseUrl + post.fields.slug.replace("/comics/", "/")

    // ====

    return (
      <Layout activeTheme={post.frontmatter.theme || "dark"} showHeader={false}>
        <SEO
          isArticle
          comicType="post"
          title={post.frontmatter.title}
          pubDateTime={post.frontmatter.date}
          description={postDescription}
          url={seoUrl}
          image={seoImg || null}
          lang={seoLang}
        />
        <StickyMenu
          postTitle={
            post.frontmatter.ep
              ? post.frontmatter.ep + ": " + post.frontmatter.title
              : post.frontmatter.title
          }
          postUrl={this.props.location.href}
          parentPostTitle={parentPostTitle}
          parentPostUrl={parentPostUrl}
          prevUrl={prevUrl}
          nextUrl={nextUrl}
          twitterShareUrl={twitterShareUrl}
          fbShareUrl={fbShareUrl}
        >
          <ComicBody
            dangerouslySetInnerHTML={{ __html: post.html }}
            className="AcComicBody"
            mb={[3, 4, 5]}
          />

          <SocialListTextOnly
            twitterUrl={twitterShareUrl}
            fbUrl={fbShareUrl}
            postUrl={this.props.location.href}
            activeTheme={post.frontmatter.theme || "dark"}
          />

          {typeof siblingCarousel !== "undefined" && siblingCarousel}
        </StickyMenu>
      </Layout>
    )
  }
}

export default ComicPostTemplate

export const pageQuery = graphql`
  query ComicPostBySlug(
    $slug: String!
    $parentSlug: String! # $grandParentSlug: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        ep
        date(formatString: "YYYY-MM-DD") # for SEO
        description
        theme
        lang
      }
    }
    parentPost: markdownRemark(fields: { slug: { eq: $parentSlug } }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        thumbnail {
          # for SEO image
          childImageSharp {
            resize(width: 512, height: 256, quality: 25) {
              src
            }
          }
        }
      }
    }
  }
`
