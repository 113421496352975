import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import {
  CardItemWrapper,
  CardThumbnail,
  // CardLink,
  CardButton,
  CardButtonExt,
  CardTitle,
  CardDesc,
  ListItemWrapper,
  ListThumbnail,
  ListLink,
  ListTitle,
  ListDate,
  MiniItemWrapper,
  MiniThumbnail,
  MiniLink,
  MiniTitle,
} from "./ComicListItem.css"

///

const ComicListItem = props => {
  const {
    variant,
    itemTitle,
    url,
    thumbnail,
    thumbnailFluid,
    date,
    description,
    urlExt1,
    urlExt2,
    lang,
  } = props

  const cardItem = (
    <CardItemWrapper activeClassName="is-active" className="AcComic">
      <CardTitle>{itemTitle}</CardTitle>
      <CardThumbnail>
        <Img fluid={thumbnailFluid} alt="" aria-hidden />
      </CardThumbnail>
      <CardDesc>
        <p>{description && description}</p>
        <div>
          {lang &&
            lang.map(language =>
              language === "en" ? (
                <CardButton to={lang.length > 1 ? url + "en" : url} key="link-en">
                  Read (English)
                </CardButton>
              ) : language === "id" ? (
                <CardButton to={lang.length > 1 ? url + "id" : url} key="link-id">
                  Baca (Indonesian)
                </CardButton>
              ) : language === "non" ? (
                <CardButton to={lang.length > 1 ? url + "non" : url} key="link-non">
                  Read/Baca
                </CardButton>
              ) : language === "samen" ? (
                <CardButton to={lang.length > 1 ? url + "samen" : url} key="link-samen">
                  Preview Sample
                </CardButton>
              ) : language === "samid" ? (
                <CardButton to={lang.length > 1 ? url + "samid" : url} key="link-samid">
                  Baca Sampel
                </CardButton>
              ) :(
                false
              )
            )}
          {urlExt1 && (
            <CardButtonExt href={urlExt1.url}>
              {urlExt1.label}{" "}
              <span className="external-icon" aria-hidden>
                &#x2197;&#xFE0E;
              </span>
            </CardButtonExt>
          )}
          {urlExt2 && (
            <CardButtonExt href={urlExt2.url}>
              {urlExt2.label}{" "}
              <span className="external-icon" aria-hidden>
                &#x2197;&#xFE0E;
              </span>
            </CardButtonExt>
          )}
        </div>
      </CardDesc>
    </CardItemWrapper>
  )

  const listItem = (
    <ListItemWrapper activeClassName="is-active">
      <ListThumbnail>{thumbnail ? <img src={thumbnail} alt="" aria-hidden /> : ""}</ListThumbnail>
      <ListLink to={url}>
        <ListTitle>{itemTitle}</ListTitle>
      </ListLink>
      <ListDate>{date && date}</ListDate>
    </ListItemWrapper>
  )

  const miniItem = (
    <MiniItemWrapper
      activeClassName="is-active"
      className="AcComic"
      {...props}
      tabIndex={undefined}
    >
      <MiniLink to={url} activeClassName="is-active">
        <MiniThumbnail>{thumbnail ? <img src={thumbnail} alt="" aria-hidden /> : ""}</MiniThumbnail>
        <MiniTitle>{itemTitle}</MiniTitle>
      </MiniLink>
    </MiniItemWrapper>
  )

  switch (variant) {
    default:
    case "card":
      return cardItem
    // break
    case "list":
      return listItem
    // break
    case "mini":
      return miniItem
    // break
  }
}

ComicListItem.propTypes = {
  variant: PropTypes.oneOf(["card", "list", "mini"]),
  itemTitle: PropTypes.string.isRequired,
  url: PropTypes.string,
  thumbnail: PropTypes.string,
  thumbnailFluid: PropTypes.object,
  date: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  urlExt1: PropTypes.object,
  urlExt2: PropTypes.object,
  lang: PropTypes.array,
}

ComicListItem.defaultProps = {
  variant: "card",
  itemTitle: "Comic or Episode Title",
  url: "/",
}

export default ComicListItem
