import React, { Component } from "react"
import PropTypes from "prop-types"
import { TextOnlySocialLink, TextOnlySocialWrapper } from "./SocialList.css"

///

class SocialListTextOnly extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false,
      canCopy: true,
    }
  }

  componentDidMount() {
    if (!document.queryCommandSupported("copy")) {
      this.setState({ canCopy: false })
    }
  }

  // copy post URL to clipboard
  // https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
  handleCopyText = e => {
    var tempInput = document.createElement("input")
    tempInput.value = this.props.postUrl
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand("copy")
    document.body.removeChild(tempInput)
    this.setState({ isCopied: true })
    this.copyBtnEl.focus() // retain focus after copying link (for keyboard users)
  }

  render() {
    const { twitterUrl, fbUrl } = this.props

    return (
      <TextOnlySocialWrapper className="AcShareTxtOnly">
        <div className="h">Share this page</div>
        <TextOnlySocialLink
          activeTheme={this.props.activeTheme}
          href={fbUrl}
          style={{ marginLeft: 0 }}
        >
          Facebook
        </TextOnlySocialLink>
        <TextOnlySocialLink
          activeTheme={this.props.activeTheme}
          href={twitterUrl}
        >
          Twitter
        </TextOnlySocialLink>

        {this.state.canCopy && (
          <TextOnlySocialLink
            onClick={this.handleCopyText}
            ref={element => {
              this.copyBtnEl = element
            }}
            activeTheme={this.props.activeTheme}
            className="hide-no-js"
            aria-hidden="true"
            as="button"
            rel=""
            target=""
          >
            Copy Link
            {this.state.isCopied && (
              <span style={{ fontSize: 14, verticalAlign: "top" }}> ✔</span>
            )}
          </TextOnlySocialLink>
        )}
      </TextOnlySocialWrapper>
    )
  }
}

SocialListTextOnly.propTypes = {
  twitterUrl: PropTypes.string,
  fbUrl: PropTypes.string,
  postUrl: PropTypes.string,
}

export default SocialListTextOnly
