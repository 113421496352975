import React, { Component } from "react"
import PropTypes from "prop-types"
import VisuallyHidden from "@reach/visually-hidden"
import { SocialLink, SocialIcon, ShareLabel } from "./SocialList.css"

///

class SocialList extends Component {
  render() {
    return (
      <div className="AcShare">
        <ShareLabel>Share&nbsp;</ShareLabel>
        <SocialLink href={this.props.fbUrl} bg="#3b5998">
          <SocialIcon
            xmlns="http://www.w3.org/2000/SocialIcon"
            viewBox="0 0 96.124 96.123"
          >
            <path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803 c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654 c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246 c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" />
          </SocialIcon>
          <VisuallyHidden>Facebook</VisuallyHidden>
        </SocialLink>
        &nbsp;
        <SocialLink href={this.props.twitterUrl} bg="#38A1F3">
          <SocialIcon
            xmlns="http://www.w3.org/2000/SocialIcon"
            viewBox="0 0 273.4 222.2"
          >
            <path d="M273.4,26.3c-10.1,4.5-20.9,7.5-32.2,8.8c11.6-6.9,20.5-17.9,24.7-31C255,10.5,243,15.2,230.2,17.7 C220,6.8,205.4,0,189.3,0c-31,0-56.1,25.1-56.1,56.1c0,4.4,0.5,8.7,1.5,12.8C88,66.5,46.7,44.2,19,10.3c-4.8,8.3-7.6,17.9-7.6,28.2 c0,19.5,9.9,36.6,25,46.7c-9.2-0.3-17.8-2.8-25.4-7c0,0.2,0,0.5,0,0.7c0,27.2,19.3,49.8,45,55c-4.7,1.3-9.7,2-14.8,2 c-3.6,0-7.1-0.4-10.6-1c7.1,22.3,27.9,38.5,52.4,39c-19.2,15-43.4,24-69.7,24c-4.5,0-9-0.3-13.4-0.8c24.8,15.9,54.3,25.2,86,25.2 c103.2,0,159.6-85.5,159.6-159.6c0-2.4-0.1-4.9-0.2-7.3C256.4,47.4,265.9,37.5,273.4,26.3z" />
          </SocialIcon>
          <VisuallyHidden>Twitter</VisuallyHidden>
        </SocialLink>
      </div>
    )
  }
}

SocialList.propTypes = {
  twitterUrl: PropTypes.string,
  fbUrl: PropTypes.string,
}

export default SocialList
