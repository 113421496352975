import styled from "styled-components"
import { color, space } from "styled-system"
import { mq } from "../../../config/mq"
import theme from "../../../config/theme"
import {
  SocialLink as PlainSocialLink,
  SocialIcon as PlainSocialIcon,
} from "../Header/Header.css"

///

export const SocialLink = styled(PlainSocialLink)`
  ${color}
  ${space}

  border-radius: 0.125rem;
  margin-top: -2px; // bugfix
  opacity: 0.9;

  &:hover,
  &:focus {
    opacity: 1;
  }
`
SocialLink.defaultProps = {
  ml: [0, 0, 2],
}

///

export const TextOnlySocialLink = styled(PlainSocialLink)`
  ${space}

  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  color: currentColor;
  border: 1px solid currentColor;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.activeTheme === "dark"
        ? theme.colors.fgMuted[0]
        : theme.colors.secondary};
  }
`
TextOnlySocialLink.defaultProps = {
  mx: [1, 2],
  px: 3,
  py: 2,
}

///

export const SocialIcon = styled(PlainSocialIcon)`
  width: 1rem;
  height: 1rem;
  color: #fff;
`

///

export const ShareLabel = styled.span`
  display: none;

  ${mq.sm(`display: inline-block;`)}
`

///

export const TextOnlySocialWrapper = styled.div`
  ${space}

  text-align: center;
  font-family: ${theme.fonts.heading};

  .h {
    margin-bottom: 1rem;
  }
`
TextOnlySocialWrapper.defaultProps = {
  my: [4, 5],
}
