import PropTypes from "prop-types"
import React from "react"
import ComicListItem from "../ComicListItem"
import { DefaultWrapper } from "./ComicList.css"
import { SortWrapper, SortButton } from "../SortMenu/SortMenu.css"
import VisuallyHidden from "@reach/visually-hidden"

///

class ComicList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { sortBy: "newest", posts: [] }
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        posts: this.props.data,
      })
    }
  }

  reversePosts(data) {
    this.setState({
      posts: data.reverse(),
    })
  }

  sortOldestPosts(data) {
    let sortedData = data.sort((a, b) => {
      if (a.node.frontmatter.date < b.node.frontmatter.date) {
        return -1
      } else if (a.node.frontmatter.date > b.node.frontmatter.date) {
        return 1
      }
      return 0
    })
    this.setState({
      posts: sortedData,
    })
  }

  alphabetizePosts(data) {
    let sortedData = data.sort((a, b) => {
      if (a.node.frontmatter.title < b.node.frontmatter.title) return -1
      else if (a.node.frontmatter.title > b.node.frontmatter.title) return 1
      return 0
    })
    this.setState({
      posts: sortedData,
    })
  }

  handleSort(event) {
    const { value } = event.target
    this.setState({
      sortBy: value,
    })

    if (value === "newest" || value === "oldest") {
      if (this.state.sortBy === "newest" || this.state.sortBy === "oldest") {
        this.reversePosts(this.state.posts)
      } else {
        switch (value) {
          default:
          case "newest":
            this.sortOldestPosts(this.state.posts)
            this.reversePosts(this.state.posts)
            break
          case "oldest":
            this.sortOldestPosts(this.state.posts)
            break
        }
      }
    }

    if (value === "alpha") {
      this.alphabetizePosts(this.state.posts)
    }
  }

  render() {
    return (
      <>
        <SortWrapper onChange={event => this.handleSort(event)}>
          <VisuallyHidden>
            <legend>Sort by...</legend>
          </VisuallyHidden>

          <SortButton
            htmlFor="sortByNewest"
            className={this.state.sortBy === "newest" ? "is-active" : ""}
          >
            <input
              type="radio"
              id="sortByNewest"
              name="sortBy"
              value="newest"
            />
            <span>Newest First</span>
          </SortButton>

          <SortButton
            htmlFor="sortByOldest"
            className={this.state.sortBy === "oldest" ? "is-active" : ""}
          >
            <input
              type="radio"
              id="sortByOldest"
              name="sortBy"
              value="oldest"
            />
            <span>Oldest First</span>
          </SortButton>

          <SortButton
            htmlFor="sortByAlpha"
            className={this.state.sortBy === "alpha" ? "is-active" : ""}
          >
            <input type="radio" id="sortByAlpha" name="sortBy" value="alpha" />
            <span>Alphabetical</span>
          </SortButton>
        </SortWrapper>

        <DefaultWrapper>
          {this.state.posts
            ? this.state.posts.map(({ node }) => (
                <ComicListItem
                  key={node.fields.slug}
                  variant={this.props.variant}
                  itemTitle={
                    node.frontmatter.ep
                      ? node.frontmatter.ep + ": " + node.frontmatter.title
                      : node.frontmatter.title
                  }
                  date={node.frontmatter.date}
                  lang={node.frontmatter.lang}
                  url={node.fields.slug.replace("comics/", "")}
                  urlExt1={
                    node.frontmatter.linkExternal
                      ? node.frontmatter.linkExternal[0]
                      : null
                  }
                  urlExt2={
                    node.frontmatter.linkExternal
                      ? node.frontmatter.linkExternal[1]
                      : null
                  }
                  thumbnail={
                    node.frontmatter.thumbnail.childImageSharp &&
                    node.frontmatter.thumbnail.childImageSharp.resize
                      ? node.frontmatter.thumbnail.childImageSharp.resize.src
                      : null
                  }
                  thumbnailFluid={
                    node.frontmatter.thumbnail.childImageSharp &&
                    node.frontmatter.thumbnail.childImageSharp.fluid
                      ? node.frontmatter.thumbnail.childImageSharp.fluid
                      : null
                  }
                  description={node.frontmatter.description || null}
                />
              ))
            : this.props.children
            ? this.props.children
            : false}
        </DefaultWrapper>
      </>
    )
  }
}

ComicList.propTypes = {
  children: PropTypes.node,
  data: PropTypes.any,
  variant: PropTypes.oneOf(["card", "list"]),
}

ComicList.defaultProps = {
  variant: "card",
}

export default ComicList
