import styled from "styled-components"
import theme from "../../../config/theme"
import { mq } from "../../../config/mq"
import { fontSize, space, width } from "styled-system"
import { transparentize } from "polished"

///

export const SortButton = styled.label`
  ${fontSize}
  ${space}
  ${width}

  font-family: ${theme.fonts.heading};
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: 2px solid ${theme.colors.fg};

  &:hover {
    background-color: ${theme.colors.secondary};
  }

  &:not(.is-active) {
    border-top-color: transparent;
    border-bottom-color: transparent;

    &:first-of-type {
      border-left-color: transparent;
    }

    &:last-of-type {
      border-right-color: transparent;
    }
  }

  &.is-active {
    background-color: ${transparentize(0.75, theme.colors.accent)};
    border-color: ${theme.colors.fg};
  }

  &:not(:only-child):not(:first-child) {
    margin-left: -0.125rem;
  }

  input {
    appearance: none;
    -webkit-appearance: none;

    &[type="radio"] {
      // Safari iOS
      width: 0; 
      height: 0;
      visibility: hidden;
    }
  }
`
SortButton.defaultProps = {
  fontSize: [1, 2],
  px: [2, 3],
  py: 3,
  width: ["33.33%", "auto"],
}

///

export const SortWrapper = styled.fieldset`
  ${space}

  border: none;

  ${mq.xs(`text-align: center;`)}
`
SortWrapper.defaultProps = {
  p: 0,
  mt: [3, -2],
  mb: 4,
}
