import styled, { css } from "styled-components"
import theme from "../../../config/theme"
import { mq } from "../../../config/mq"
import { truncate } from "../../../config/mixins"
import { fontSize, space } from "styled-system"
import { Link } from "gatsby"

///

const menuHeight = 3

///

export const NextPrevNav = styled(Link)`
  ${space}
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${theme.colors.accent};
  }
`
NextPrevNav.defaultProps = {
  p: 2,
}

///

const gridXs = css`
  grid-template-columns: 1fr 12rem 1fr;
`
const gridSm = css`
  grid-template-columns: 1fr 18rem 1fr;
`
const gridGeneral = css`
  grid-template-columns: minmax(11rem, 1fr) 2fr minmax(11rem, 1fr);
`

const menuBarStyle = css`
  display: grid;
  align-items: center;
  z-index: 9;
  ${gridXs}

  ${mq.xs(gridSm)}
  ${mq.sm(gridGeneral)}

  > *:nth-child(1) {
    ${truncate()}
  }

  > *:nth-child(2) {
    ${truncate()}
    text-align: center;

    * { vertical-align: middle; }
  }

  > *:nth-child(3) {
    text-align: right;
  }

  a:not(:hover) {
    text-decoration: none;
  }

  .episode-title {
    // truncate both container and .episode-title for large and phone views
    ${truncate()}

    display: inline-block;
    text-transform: uppercase;
    padding: 0 .5rem;
    font-size: 100%;

    ${mq.xs(`max-width: 20rem;`)}
    ${mq.sm(`max-width: 19rem;`)}
    ${mq.md(`max-width: 26rem;`)}

    // balance if no next/prev arrow
    ${mq.sm(`
      &:first-child {
        margin-left: 2rem;
      }
      &:last-child {
        margin-right: 2rem;
      }
    `)}
  }

  @supports not (display: grid) {
    display: flex;

    > *:nth-child(2) {
      flex-grow: 1;
    }
  }
`

///

const MenuBase = styled.div`
  ${menuBarStyle}
  ${fontSize}

  transition: all 0.2s ease;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: ${menuHeight}rem;
  left: 0;
  right: 0;
  padding: 0.5rem;
  font-family: ${theme.fonts.heading};
  font-weight: 700;
  background: ${theme.colors.fg};
  color: ${theme.colors.darkMode.white};

  ${mq.sm(`
    padding: 0.5rem 1rem;
  `)}

  &.is-hidden {
    opacity: 0;
  }
`
MenuBase.defaultProps = {
  fontSize: [1, 1, 2],
}

///

export const TopMenu = styled(MenuBase)`
  top: 0;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  &.is-hidden {
    transform: translateY(-${menuHeight}rem);
  }

  ${NextPrevNav} {
    display: none;

    ${mq.sm(` display: inline-block; `)}
  }
`

///

export const BottomMenu = styled(MenuBase)`
  bottom: 0;
  border-top: 1px solid ${theme.colors.darkMode.white};

  &.is-hidden {
    transform: translateY(${menuHeight}rem);
  }

  ${mq.sm(` display: none; `)}

  ${NextPrevNav} {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 1rem;
    color: ${theme.colors.darkMode.white};
    font-weight: 700;
  }

  .back-to-top {
    text-align: center;
    grid-column-start: 2;
  }
`

///

export const BackTopButton = styled.button`
  background: ${theme.colors.darkMode.white};
  transition: all 0.2s ease;
  display: none;
  position: fixed;
  border-radius: 0.125rem;
  padding: 0.5rem 0.75rem;
  font-weight: 700;
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }

  ${mq.sm(`
    display: inline-block;
    bottom: 2rem;
    right: 1rem;
  `)}
  ${mq.md(`
    bottom: 2rem;
    right: 2rem;
  `)}

  &.is-hidden {
    opacity: 0;
    transform: translateY(4rem);
  }
`

///

export const Content = styled.div`
  max-width: 45rem;
  margin: 1rem auto 0;

  img {
    width: 100%;
  }

  .AcComicBody {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`

///
