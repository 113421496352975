import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { TopMenu, BottomMenu, BackTopButton, Content, NextPrevNav } from "./StickyMenu.css"
import SocialList from "../SocialList"

///

const BackLink = styled(Link)`
  height: 100%;
  line-height: 32px;
`

class StickyMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: true }
  }

  componentWillUnmount() {
    window.onscroll = function() {}
  }

  componentDidMount() {
    // define current component, to make `setState` work
    // https://www.freecodecamp.org/forum/t/react-question-cannot-read-property-setstate-of-undefined/69620/3

    let currentComponent = this

    // use timeout to fix bug hiding menu on page load
    setTimeout(function() {
      window.onscroll = function(e) {
        // show menu if direction is up
        if (this.oldScroll > this.scrollY) {
          if (!currentComponent.state.showMenu) {
            currentComponent.setState({ showMenu: true })
          }
        } else {
          if (currentComponent.state.showMenu) {
            currentComponent.setState({ showMenu: false })
          }
        }
        this.oldScroll = this.scrollY
      }
    }, 1000)
  }

  handleBackToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  render() {
    const {
      children,
      postTitle,
      parentPostTitle,
      parentPostUrl,
      prevUrl,
      nextUrl,
      twitterShareUrl,
      fbShareUrl,
    } = this.props

    // ====

    return (
      <React.Fragment>
        <TopMenu className={this.state.showMenu ? "" : "is-hidden"}>
          <BackLink to={parentPostUrl}>← {parentPostTitle}</BackLink>
          <div>
            {prevUrl && <NextPrevNav to={prevUrl}>&#x25c4;</NextPrevNav>}
            <h2 className="episode-title">{postTitle}</h2>
            {nextUrl && <NextPrevNav to={nextUrl}>&#x25ba;</NextPrevNav>}
          </div>
          <SocialList twitterUrl={twitterShareUrl} fbUrl={fbShareUrl} />
        </TopMenu>
        <Content>{children && children}</Content>
        <BackTopButton
          className={this.state.showMenu ? "" : "is-hidden"}
          onClick={this.handleBackToTop}
        >
          &#x25b2;
          <br />
          <small>TOP</small>
        </BackTopButton>
        <BottomMenu className={this.state.showMenu ? "" : "is-hidden"}>
          {prevUrl && <NextPrevNav to={prevUrl}>&#x25c4; Prev</NextPrevNav>}
          <div className="back-to-top">
            <button onClick={this.handleBackToTop}>&#x25b2; Back to Top</button>
          </div>
          {nextUrl && <NextPrevNav to={nextUrl}>Next &#x25ba;</NextPrevNav>}
        </BottomMenu>
      </React.Fragment>
    )
  }
}

StickyMenu.propTypes = {
  children: PropTypes.node,
  postTitle: PropTypes.string.isRequired,
  postUrl: PropTypes.string,
  parentPostTitle: PropTypes.string.isRequired,
  parentPostUrl: PropTypes.string.isRequired,
  prevUrl: PropTypes.string,
  nextUrl: PropTypes.string,
}

StickyMenu.defaultProps = {
  postTitle: "Post Title",
  parentPostTitle: "Azam's Comics",
  parentPostUrl: "/",
}

export default StickyMenu
