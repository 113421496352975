import styled, { css } from "styled-components"
import { mq } from "../../../config/mq"
import theme from "../../../config/theme"
import { outerContainer } from "../../../config/mixins"
import { space } from "styled-system"
// import { Link } from "gatsby"
// import { transparentize } from "polished"

///

export const DefaultWrapper = styled.ul`
  ${space}

  ////// option 1

  ${outerContainer()}

  >.AcComic {
    margin-left: auto;
    margin-right: auto;
  }

  ////// option 2

  /* ${outerContainer()}
  max-width: 80rem;

  ${mq.xs(`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1.5rem 1.5rem;
  `)}

  >.AcComic {
    width: 100%;
    max-width: 9999px;
    margin-bottom: 0;
  } */
`

///

const navBtnWidth = "3rem"
const thumbImgWidth = 6

const navPaddingStyle = css`
  padding: 0 ${navBtnWidth};
`

const comicItemStyle = css`
  // for small screens, use percentage-based width
  // for medium & larger, use fixed width

  padding-top: 0.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  min-width: 4.5rem; // fallback
  width: 20vw; // 5-column grid = 20% of screen width

  img {
    width: calc(20vw - 0.5rem);
    height: calc(20vw - 0.5rem);
  }

  @supports not (object-fit: cover) {
    img {
      width: 4rem;
      height: 4rem;
    }
  }

  ${mq.xs(`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: ${thumbImgWidth + 2}rem;

    img {
      width: ${thumbImgWidth + 1}rem;
      height: ${thumbImgWidth + 1}rem;
    }
  `)}
  ${mq.sm(`
    width: ${thumbImgWidth + 1}rem;

    img {
      width: ${thumbImgWidth}rem;
      height: ${thumbImgWidth}rem;
    }
  `)}
`

export const CarouselWrapper = styled.div`
  ${outerContainer()}

  .ArcCarousel-nav-btn {
    width: ${navBtnWidth};
    height: ${navBtnWidth};
    border: none;
    border-radius: 50%;
    background: transparent;
    opacity: 0.75;
    color: inherit; // override buttontext
    display: none; // override with mq

    &:not([disabled]) {
      cursor: pointer;

      &:hover,
      &:active {
        opacity: 1;
      }

      &:focus {
        box-shadow: 0 0 4px 1px rgba(255,255,255,0.25);
        color: ${theme.colors.accent};
      }

      &:active {
        transform: translateY(1px);
      }
    }

    &[disabled] {
      opacity: 0.25;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }

    ${mq.sm(`display: inline-block`)}
  }

  .AcComic {
    ${comicItemStyle}
  }

  // Nuka carousel components

  .slider {
    ${mq.sm(navPaddingStyle)}
  }

  .slider-frame {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
    padding: 0 1rem !important;

    ${mq.sm(`
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 0 8px 0 6px !important;
    `)}
  }

  .slider-list {
    margin: 0 auto !important;
    /* min-height: 5.5rem; // fallback
    height: calc(
      100vw / 6 + 1.5rem
    ) !important; // (each grid is 1/6 of the screen) */

    /* ${mq.sm(`height: 8rem !important;`)} */
  }

  .slider-slide {
    ${mq.sm(`width: ${thumbImgWidth + 1}rem !important;`)}
  }
`
